import { Stack, Typography } from "@mui/material"
import Logo from "../../assets/maaz-logo.png";

const ClientInfo = () => {
    return(
        <Stack
        sx={{ mb: 2, mt: 1 }}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"end"}
        spacing={3}>
        <img src={Logo} alt="logo" width={"250"} height={"70"} />

        <Stack>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
            ZR Traders
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Shop No 8, Ali Market Sabzi Mandi,
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Raja Bazar Rawalpindi. Ph:051-5557038
          </Typography>
          <Typography sx={{ fontSize: "11px" }}>
            Cell: 03205337777 Email: info@maazpro.com 
          </Typography>
        </Stack>
      </Stack>
    )
}

export default ClientInfo